import {
  Box,
  Collapse,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  LinkProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Square,
  Text,
  TextProps,
  Tooltip,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react'
import {
  Icon as TablerIcon,
  IconApps,
  IconBinoculars,
  IconBolt,
  IconChevronDown,
  IconChevronRight,
  IconChevronsLeft,
  IconChevronsRight,
  IconCircle,
  IconClock,
  IconDashboard,
  IconDots,
  IconDotsVertical,
  IconEye,
  IconEyeOff,
  IconLayoutSidebarLeftExpand,
  IconList,
  IconMessageChatbot,
  IconPlus,
  IconSettings,
  IconSettingsAutomation,
  IconSmartHome,
  IconTools,
  IconTrendingUp,
  IconUserCircle,
  IconUsers,
  IconUsersGroup,
  IconWorld
} from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import React, { forwardRef, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { toast } from 'sonner'
import createPersistedState from 'use-persisted-state'
import { AccountView } from '../../../types/AccountView'
import { Space } from '../../../types/Space'
import { useAccountViews } from '../../data/use-account-views'
import { useOnboardingChecks } from '../../data/use-onboarding-checks'
import { useSpaces } from '../../data/use-spaces'
import { useUpdateSetting } from '../../data/use-update-setting'
import { useUserSettings } from '../../data/use-user-settings'
import Avatar from '../../ui/Avatar'
import { AccountsBanner } from '../../ui/billing-banners/accounts-banner'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { FeedbackForm } from '../../ui/FeedbackForm'
import { Iconify } from '../../ui/Iconify'
import { BuildingIcon, PieChartIcon } from '../../ui/icons'
import { SearchIcon } from '../../ui/icons/SearchIcon'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { useTopBarContext } from '../../ui/TopBarContext'
import { NotificationBadge, SearchBar, useChatStuff } from '../../ui/TopNav'
import { useLayoutMode } from '../../ui/useLayoutMode'
import { useOverflow } from '../../ui/useOverflow'
import { useCurrentUser } from '../../ui/UserContext'
import { WorkspaceMenu } from '../../ui/WorkspaceMenu'
import { NewListModal } from '../account_views/components/NewListModal'
import { SetupBadge } from '../onboarding/components/SetupBadge'
import { NewSpaceModal } from '../spaces/components/NewSpaceModal'
import { Team } from '../teams/show'
import { useTrackRecentNavItems, useUserNavSettingsContext } from './useTrackRecentNavItems'

interface SideNavContextProps {
  isOpen: boolean
  isCollapsed: boolean
  offScreen: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
}

export const SideNavContext = React.createContext<SideNavContextProps>({
  isOpen: true,
  isCollapsed: false,
  offScreen: false,
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {}
})

export function useSideNav() {
  return React.useContext(SideNavContext)
}

interface SideNavProps {
  path: string
  animated?: boolean
  onSearchClick?: () => void
}

export function SideNav(props: SideNavProps) {
  const { isOpen, isCollapsed, offScreen, onClose, onOpen } = useSideNav()
  const project = useCurrentProject()
  const currentUser = useCurrentUser()
  const settings = useUserSettings()
  const onboarding = useOnboardingChecks()
  const views = useAccountViews({ ownership: ['team', 'private', 'shared'] })
  const spaces = useSpaces({ ownership: 'shared' })

  // group views by ownership (shared, team, private)
  // so we can render them differently
  const groupedViews = useMemo(() => {
    const grouped = {
      others: [] as AccountView[],
      team: {} as Record<string, AccountView[]>
    }

    for (const view of views.data?.account_views || []) {
      if (view.ownership === 'team' && view.team_id) {
        grouped.team[view.team_id] = grouped.team[view.team_id] || []
        grouped.team[view.team_id].push(view)
      } else {
        grouped.others.push(view)
      }
    }

    return grouped
  }, [views.data])

  const { mutateAsync: updateUserSetting, isLoading: updatingSetting } = useUpdateSetting()

  const { visitorCount } = useChatStuff()
  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })

  const [shortScreen] = useMediaQuery('(max-height: 600px)')
  const iconSize = isCollapsed ? 20 : 18
  const { scrollRef, overflowTop } = useOverflow()
  const newSpace = useDisclosure()
  const newList = useDisclosure()
  const hideMissionControl = useDisclosure()

  const displayMissionControl =
    project?.mission_control_enabled && (settings.data?.settings?.mission_control?.display ?? true)
  const refetchSettings = settings.refetch

  useEffect(() => {
    const onSettingsUpdate = async (e) => {
      // no need to refetch when changing other stuff
      if (e.detail?.setting_type !== 'mission_control') {
        return
      }

      try {
        await refetchSettings()
      } catch (error) {
        console.error(error)
      }
    }

    window.addEventListener('settingschanged', onSettingsUpdate)

    return () => {
      window.removeEventListener('settingschanged', onSettingsUpdate)
    }
  }, [refetchSettings])

  return (
    <Flex
      as="nav"
      ref={scrollRef}
      role="navigation"
      aria-label="Main"
      flex="0 0 auto"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="stretch"
      bg="gray.50"
      borderRight="1px solid"
      borderColor="border.lightest"
      height="100%"
      overflow={isCollapsed || shortScreen ? 'auto' : 'hidden'}
      color="gray.700"
      position="absolute"
      top={0}
      bottom={0}
      _hover={{
        '& .nav-collapse-button': { color: 'gray.500', opacity: 1 }
      }}
      {...(isCollapsed
        ? {
            width: '100%',
            maxWidth: 'min(20vw, 60px)',
            right: 0
          }
        : {
            width: ['240px', '250px', '260px'],
            maxWidth: 'min(100vw, 350px)',
            minW: '240px',
            transform: `translateX(${isOpen ? '0' : '-100%'})`,
            transition: props.animated ? 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1)' : undefined
          })}
    >
      <Flex
        flex="none"
        position="sticky"
        zIndex="sticky"
        top={0}
        alignItems="stretch"
        justifyContent="space-between"
        pr={isCollapsed ? undefined : 2.5}
        height="48px"
        bg="gray.50"
        borderBottom="1px solid"
        borderColor={overflowTop ? 'gray.100' : 'transparent'}
        _hover={{ bg: 'background.hover' }}
      >
        {project && (
          <WorkspaceMenu project={project} canManageMembers={canManageMembers}>
            {isCollapsed ? (
              <Flex alignItems="center" justifyContent="center">
                <Square bg="white" rounded="lg" padding={0.5}>
                  <CompanyAvatar
                    size="22px"
                    name={project.name}
                    domain={project.domain || project.potential_domain}
                    rounded="md"
                  />
                </Square>
              </Flex>
            ) : (
              <HStack flex="1 1 auto" spacing={3} overflow="hidden" px={4}>
                <Square bg="white" rounded="lg" padding={0.5}>
                  <CompanyAvatar
                    size="24px"
                    name={project.name}
                    domain={project.domain || project.potential_domain}
                    rounded="md"
                  />
                </Square>
                <HStack spacing={1.5} overflow="hidden">
                  <Text fontSize="md" fontWeight="semibold" letterSpacing="tight" userSelect="none" isTruncated>
                    {project.name || project.slug}
                  </Text>
                  <Icon as={IconChevronDown} boxSize={3.5} />
                </HStack>
              </HStack>
            )}
          </WorkspaceMenu>
        )}
        {!isCollapsed && !offScreen && (
          <Icon
            as={IconChevronsLeft}
            boxSize={5}
            alignSelf="center"
            flex="none"
            color="gray.400"
            opacity={0}
            cursor="pointer"
            className="nav-collapse-button"
            style={{
              strokeWidth: 1.75
            }}
            transition="all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            onClick={onClose}
          />
        )}
      </Flex>

      <Flex
        flexBasis="auto"
        flexGrow={1}
        flexShrink={isCollapsed || shortScreen ? 0 : 1}
        flexDirection="column"
        px={3}
        pt={isCollapsed ? 1 : 3}
        overflow="auto"
      >
        <Flex flexDirection="column" gap={isCollapsed ? 1 : 5}>
          {!offScreen && isCollapsed && (
            <Box paddingBottom={3}>
              <NavLink icon={<IconChevronsRight size={iconSize} />} onClick={onOpen}>
                Expand Menu
              </NavLink>
            </Box>
          )}

          <Flex flexDirection="column" gap={isCollapsed ? '2px' : '1px'}>
            <Box paddingBottom={isCollapsed ? 0 : 3}>
              {isCollapsed ? (
                <NavLink icon={<SearchIcon size={iconSize} />} onClick={props.onSearchClick}>
                  Search
                </NavLink>
              ) : (
                <SearchBar bg="white" height="32px" placeholder="Search" onClick={props.onSearchClick} />
              )}
            </Box>

            {currentUser.isInternalUser ? (
              <NavLink icon={<IconSmartHome size={iconSize} />} href={projectPath('/home')}>
                Home
              </NavLink>
            ) : displayMissionControl ? (
              <NavLink
                icon={<IconDashboard size={iconSize} />}
                href={projectPath('/mission-control')}
                isActive={
                  window.location.href.includes(projectPath('/mission-control')) &&
                  !window.location.pathname.includes('/mission-control/templates') &&
                  !window.location.pathname.endsWith('/setup')
                }
                menu={
                  <Menu placement="bottom-start">
                    <MenuButton
                      size="tiny"
                      as={IconButton}
                      icon={<IconDotsVertical size={15} />}
                      variant="ghost"
                      visibility="hidden"
                      _groupHover={{ visibility: 'visible' }}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    />
                    <Portal>
                      <MenuList fontSize="sm" zIndex="popover">
                        {settings.data?.settings?.mission_control?.display ? (
                          <MenuItem icon={<IconEyeOff size={16} />} onClick={hideMissionControl.onOpen}>
                            Hide Mission Control…
                          </MenuItem>
                        ) : (
                          <MenuItem
                            icon={<IconEye size={16} />}
                            onClick={() => {
                              updateUserSetting({ setting_type: 'mission_control', key: 'display', value: true })
                            }}
                          >
                            Show Mission Control
                          </MenuItem>
                        )}
                      </MenuList>
                    </Portal>
                  </Menu>
                }
              >
                Mission Control
              </NavLink>
            ) : null}

            <NavLink
              icon={<IconBinoculars size={iconSize} style={{ marginTop: '-2px' }} />}
              href={projectPath('/prospector')}
            >
              Prospector
            </NavLink>

            <NavLink href={projectPath('/accounts')} icon={<BuildingIcon size={iconSize} />}>
              Accounts
            </NavLink>

            <NavLink href={projectPath('/people')} icon={<IconUsers size={iconSize} />}>
              People
            </NavLink>

            <NavLink
              href={projectPath('/live')}
              icon={
                isCollapsed ? (
                  <IconWithIndicator showIndicator={visitorCount > 0} size={iconSize} icon={IconWorld} />
                ) : (
                  <IconWorld size={iconSize} />
                )
              }
              title="Live"
            >
              <Text as="span" isTruncated>
                Live
              </Text>
              {visitorCount > 0 ? (
                <NotificationBadge paddingX={2} lineHeight={1.6} variant="subtle" marginLeft={2}>
                  {format(visitorCount)}
                </NotificationBadge>
              ) : null}
            </NavLink>
          </Flex>

          {!spaces.isLoading && spaces.data && (
            <Section
              title="Spaces"
              defaultIsOpen={(spaces.data?.spaces || []).length > 0}
              isCollapsible
              showWhenCollapsed
              rightElement={
                <Tooltip label="Add Space" placement="right">
                  <IconButton
                    aria-label="Add Space"
                    visibility="hidden"
                    size="tiny"
                    variant="ghost"
                    icon={<IconPlus size={15} strokeWidth={2} />}
                    px={0.5}
                    rounded="base"
                    color="gray.400"
                    _hover={{ color: 'gray.600' }}
                    _groupHover={{ visibility: 'visible' }}
                    onClick={newSpace.onOpen}
                  />
                </Tooltip>
              }
            >
              {spaces.data?.spaces?.map((space) => (
                <CollapsibleSpace
                  key={space.id}
                  space={space}
                  permalink={projectPath(`/spaces/${space.id}`)}
                  isActive={window.location.pathname.includes(`/spaces/${space.id}`)}
                  iconSize={iconSize}
                >
                  {space.account_views?.map((view: AccountView) => (
                    <NavLink
                      key={view.id}
                      href={view.permalink || projectPath(`/spaces/${space.id}/views/${view.slug}`)}
                      pageKey={`accountView:${view.id}`}
                      isActive={window.location.href.includes(
                        view.permalink || projectPath(`/spaces/${space.id}/views/${view.slug}`)
                      )}
                    >
                      {view.name}
                    </NavLink>
                  ))}
                </CollapsibleSpace>
              ))}
              {(spaces.data?.spaces || []).length === 0 && !isCollapsed && (
                <Text fontSize="xs" fontWeight="medium" color="gray.500" letterSpacing="tight" mx={1.5} px={1}>
                  No spaces yet
                </Text>
              )}
            </Section>
          )}

          {Object.keys(groupedViews.team).length > 0 && (
            <Section title="Teams" isCollapsible showWhenCollapsed>
              {Object.keys(groupedViews.team).map((teamId) => {
                const lists = groupedViews.team[teamId] || []
                const first = lists[0]
                const team = first?.team
                const isActive = lists.some((view) => window.location.href.includes(projectPath(`/views/${view.slug}`)))

                if (!team) {
                  return null
                }

                return (
                  <CollapsibleSpace
                    key={teamId}
                    space={team}
                    permalink={first ? projectPath(`/views/${first.slug}`) : projectPath(`/settings/teams/${team.id}`)}
                    isActive={isActive}
                    icon={<IconUsersGroup size={iconSize} />}
                    iconSize={iconSize}
                  >
                    {lists.map((view: AccountView) => (
                      <NavLink
                        key={view.id}
                        href={projectPath(`/views/${view.slug}`)}
                        pageKey={`accountView:${view.id}`}
                      >
                        {view.name}
                      </NavLink>
                    ))}
                  </CollapsibleSpace>
                )
              })}
            </Section>
          )}

          <RecentNavItems views={views.data?.account_views} spaces={spaces.data?.spaces} />

          <Section
            title="Explore"
            rightElement={
              <Tooltip label="Add List" placement="right">
                <IconButton
                  aria-label="Add List"
                  visibility="hidden"
                  size="tiny"
                  variant="ghost"
                  icon={<IconPlus size={15} strokeWidth={2} />}
                  px={0.5}
                  rounded="base"
                  color="gray.400"
                  _hover={{ color: 'gray.600' }}
                  _groupHover={{ visibility: 'visible' }}
                  onClick={newList.onOpen}
                />
              </Tooltip>
            }
          >
            <SubNavigation navKey="lists" title="Lists" icon={<IconList size={iconSize} />} iconSize={iconSize}>
              <NavLink
                href={projectPath('/views/trending')}
                pageKey="trendingAccounts"
                iconRight={<IconTrendingUp size={14} />}
              >
                Trending Accounts
              </NavLink>

              {/* there can be a ton of lists... show only the first few */}
              {groupedViews.others.slice(0, 5).map((view) => (
                <NavLink
                  key={view.id}
                  href={
                    view.permalink ||
                    (view.record_type === 'StaticList'
                      ? projectPath(`/lists/${view.id}`)
                      : projectPath(`/views/${view.slug}`))
                  }
                  pageKey={view.record_type === 'StaticList' ? `staticList:${view.id}` : `accountView:${view.id}`}
                  iconRight={view.kind === 'account' ? <BuildingIcon size={14} /> : <IconUsers size={14} />}
                >
                  {view.name}
                </NavLink>
              ))}
              {groupedViews.others.length > 5 && (
                <NavLink href={projectPath('/views')} pageKey="accountViews" title="View more lists">
                  More lists…
                </NavLink>
              )}
              <NavLink onClick={newList.onOpen}>Create a new list</NavLink>
            </SubNavigation>
            <SubNavigation
              navKey="reporting"
              title="Reporting"
              icon={<PieChartIcon size={iconSize} />}
              iconSize={iconSize}
            >
              {project?.slug !== 'vercel' && (
                <NavLink
                  href={projectPath('/analytics')}
                  pageKey="analytics"
                  // iconRight={<IconDeviceAnalytics size={iconSize - 4} />}
                >
                  Analytics
                </NavLink>
              )}

              <NavLink
                href={projectPath('/reports/contents')}
                pageKey="contentReports"
                // iconRight={<IconReport size={iconSize - 4} />}
              >
                Content Reports
              </NavLink>

              <NavLink
                href={projectPath('/utm-reports')}
                pageKey="utmReports"
                // iconRight={<IconBrowser size={iconSize - 4} />}
                isActive={location.pathname.includes('/utm-reports')}
              >
                UTM Reports
              </NavLink>
            </SubNavigation>
            <SubNavigation navKey="more" title="More" icon={<IconDots size={iconSize} />} iconSize={iconSize}>
              <NavLink
                icon={<IconBolt size={iconSize} />}
                href={projectPath('/signals')}
                pageKey="signals"
                isActive={window.location.pathname.includes('/signals')}
              >
                Intent Signals
              </NavLink>
              <NavLink
                icon={<IconUserCircle size={iconSize} />}
                href={projectPath('/settings/prospects')}
                pageKey="personas"
              >
                Personas
              </NavLink>
              <NavLink
                href={projectPath('/automations/overview')}
                icon={<IconSettingsAutomation size={iconSize} />}
                pageKey="automations"
                isActive={
                  window.location.href.includes(projectPath('/automations')) ||
                  window.location.href.includes(projectPath('/slack-alerts')) ||
                  window.location.href.includes(projectPath('/auto-outbound'))
                }
              >
                Automations
              </NavLink>

              {canManageMembers && project?.mission_control_enabled && (
                <NavLink
                  href={projectPath('/mission-control/setup')}
                  isActive={
                    window.location.pathname.includes('/mission-control/templates') ||
                    window.location.pathname.endsWith('/mission-control/setup')
                  }
                  pageKey="missionControlSetup"
                  icon={<IconTools size={iconSize} />}
                >
                  Mission Control Setup
                </NavLink>
              )}

              <NavLink href={projectPath('/apps')} icon={<IconApps size={iconSize} />}>
                Sources & Integrations
              </NavLink>

              <NavLink href={projectPath('/settings')} icon={<IconSettings size={iconSize} />}>
                Workspace Settings
              </NavLink>
            </SubNavigation>
          </Section>
        </Flex>
      </Flex>

      <Flex flexDirection="column" px={3} pt={2.5} pb={5} gap={2}>
        <Flex flexDirection="column" gap="1px">
          <FeedbackForm projectSlug={project?.slug}>
            <NavLink icon={<IconMessageChatbot size={iconSize} />}>Make a wish</NavLink>
          </FeedbackForm>

          {canManageMembers && (
            <NavLink href={projectPath('/settings')} icon={<IconSettings size={iconSize} />}>
              Settings
            </NavLink>
          )}
        </Flex>

        {!onboarding?.data?.complete && (
          <Box>
            {isCollapsed ? (
              <NavLink
                icon={<SetupBadge variant="progress" size={18} />}
                title={`Setup (${onboarding?.data?.done?.length}/${onboarding?.data?.checks?.length})`}
              >
                Setup
              </NavLink>
            ) : (
              <SetupBadge variant="full" />
            )}
          </Box>
        )}

        {!isCollapsed && project?.koala_subscription && (
          <Box marginTop={1}>
            <AccountsBanner variant="sidebar" popoverProps={{ placement: 'top-start' }} />
          </Box>
        )}
      </Flex>

      <NewSpaceModal {...newSpace} includeDescription />
      <NewListModal {...newList} />

      <DeleteConfirmation
        isCentered
        isOpen={hideMissionControl.isOpen}
        title={`Are you sure?`}
        confirmLabel="Hide Mission Control"
        onClose={hideMissionControl.onClose}
        onConfirm={(event) => {
          event.preventDefault()

          if (!updatingSetting) {
            updateUserSetting({
              setting_type: 'mission_control',
              key: 'display',
              value: false
            }).catch(() => {
              toast.error('There was an issue hiding Mission Control from the sidebar')
            })
          }
        }}
      >
        <Text fontSize="sm" color="gray.600">
          Are you sure you want to hide Mission Control? This will remove the tab from your personal sidenav. You can
          always add it back later.
        </Text>
      </DeleteConfirmation>
    </Flex>
  )
}

function getNavLink(pageKey: string, views?: AccountView[], spaces?: Space[]) {
  let href: string | null = null
  let label: string | null = null

  const id = pageKey.replace('accountView:', '').replace('staticList:', '')

  // Check if page_key matches any account views or static lists
  for (const view of views || []) {
    if (view.id === id) {
      href = view.permalink ?? null

      if (!href && view.record_type === 'StaticList') {
        href = projectPath(`/lists/${view.id}`)
      } else if (!href) {
        href = projectPath(`/views/${view.slug}`)
      }

      label = view.name
      return { href, label }
    }
  }

  // Check if page_key matches any views inside spaces
  for (const space of spaces || []) {
    for (const view of space.account_views || []) {
      if (view.id === id) {
        href = view.permalink || projectPath(`/spaces/${space.id}/views/${view.slug}`)
        label = `${space.name}: ${view.name}`
        return { href, label }
      }
    }
  }

  // Check against a predefined list of specific routes
  const specificRoutes: Record<string, { href: string; label: string }> = {
    signals: { href: projectPath('/signals'), label: 'Intent Signals' },
    automations: { href: projectPath('/automations/overview'), label: 'Automations' },
    trendingAccounts: { href: projectPath('/views/trending'), label: 'Trending Accounts' },
    personas: { href: projectPath('/settings/prospects'), label: 'Personas' },
    channelAnalytics: { href: projectPath('/channel/analytics'), label: 'Channel Analytics' },
    analytics: { href: projectPath('/analytics'), label: 'Analytics' },
    missionControlSetup: { href: projectPath('/mission-control/setup'), label: 'Mission Control Setup' },
    contentReports: { href: projectPath('/reports/contents'), label: 'Content Reports' },
    utmReports: { href: projectPath('/utm-reports'), label: 'UTM Reports' },
    accountViews: { href: projectPath('/views'), label: 'Lists' }
    // Add other specific routes here
  }

  if (specificRoutes[pageKey]) {
    return specificRoutes[pageKey]
  }

  // If no match is found, return null
  return null
}

interface RecentNavItemsProps {
  views?: AccountView[]
  spaces?: Space[]
}

function RecentNavItems(props: RecentNavItemsProps) {
  const { recentNavItems } = useUserNavSettingsContext()
  const { isCollapsed } = useSideNav()

  if (isCollapsed || !recentNavItems || recentNavItems.length === 0) {
    return null
  }

  return (
    <Section title="Shortcuts" isCollapsible>
      {recentNavItems.map((item) => {
        const navLink = getNavLink(item.page_key, props.views, props.spaces)
        if (!navLink) {
          return null
        }
        return (
          <NavLink key={item.page_key + item.created_at} href={navLink.href} icon={<IconClock size={18} />}>
            {navLink.label || item.page_key}
          </NavLink>
        )
      })}
    </Section>
  )
}

interface CollapsibleSpaceProps {
  space: Team | Space
  isActive?: boolean
  icon?: React.ReactNode
  iconSize?: number
  permalink?: string
}

function CollapsibleSpace(props: React.PropsWithChildren<CollapsibleSpaceProps>) {
  const [expandedId, setExpandedId] = createPersistedState<string>(`koala:sidebar-space`)('')
  const expanded = expandedId === props.space.id
  const { isCollapsed } = useSideNav()

  const onToggle = useCallback(() => {
    setExpandedId((prev) => (prev === props.space.id ? '' : props.space.id))
  }, [props.space.id, setExpandedId])

  const hasChildren = React.Children.toArray(props.children).length > 0
  const isActive = !expanded && props.isActive

  const icon = props.icon || (
    <Avatar
      size={`${props.iconSize}px`}
      fontSize={isCollapsed ? '11px' : '10px'}
      fontWeight="semibold"
      rounded="md"
      name={props.space.name.split(' ')[0]}
    />
  )

  return (
    <Box>
      <NavLink
        icon={icon}
        onClick={isCollapsed ? undefined : onToggle}
        href={isCollapsed || !hasChildren ? props.permalink : undefined}
        isActive={isActive}
        title={props.space.name}
        color={isActive ? 'gray.800' : undefined}
        fontWeight={isActive ? 'semibold' : 'medium'}
      >
        <Flex width="100%" gap={1} alignItems="center" justifyContent="space-between" isTruncated>
          <Text flex="1 1 auto" isTruncated>
            {props.space?.name || 'Untitled'}
          </Text>
          <Icon
            flex="none"
            as={IconChevronDown}
            boxSize={3.5}
            color="gray.400"
            ml="auto"
            transform={expanded && hasChildren ? 'rotate(180deg)' : 'rotate(0deg)'}
            transition="transform 200ms cubic-bezier(0.4, 0, 0.2, 1)"
            style={{ strokeWidth: 2.5 }}
          />
        </Flex>
      </NavLink>
      <Collapse in={expanded && !isCollapsed && hasChildren}>
        <Flex flexDir="column" gap="1px" marginBottom={1} marginLeft={4} paddingTop="1px" paddingLeft={2.5}>
          {props.children}
        </Flex>
      </Collapse>
    </Box>
  )
}

interface SubNavigationProps {
  navKey: string
  isActive?: boolean
  icon?: React.ReactNode
  iconSize?: number
  title: string
  href?: string
}

const memoryStore = (() => {
  let store: { [key: string]: string } = {}

  return {
    getItem(key: string): string | null {
      return store[key] || null
    },
    setItem(key: string, value: string): void {
      store[key] = value
    },
    removeItem(key: string): void {
      delete store[key]
    },
    clear(): void {
      store = {}
    }
  }
})()

function SubNavigation(props: React.PropsWithChildren<SubNavigationProps>) {
  const [expandedId, setExpandedId] = createPersistedState<string>(`koala:sidebar-subnav`, memoryStore)('')
  const expanded = expandedId === props.navKey
  const { isCollapsed } = useSideNav()

  const onToggle = useCallback(() => {
    setExpandedId((prev) => (prev === props.navKey ? '' : props.navKey))
  }, [props.navKey, setExpandedId])

  const hasChildren = React.Children.toArray(props.children).length > 0
  const isActive = !expanded && props.isActive

  return (
    <Box>
      <NavLink
        icon={props.icon}
        onClick={isCollapsed ? undefined : onToggle}
        href={isCollapsed || !hasChildren ? props.href : undefined}
        isActive={isActive}
        title={props.title}
        color={isActive ? 'gray.800' : undefined}
        fontWeight={isActive ? 'semibold' : 'medium'}
      >
        <Flex width="100%" gap={1} alignItems="center" justifyContent="space-between" isTruncated>
          <Text flex="1 1 auto" isTruncated>
            {props.title}
          </Text>
          <Icon
            flex="none"
            as={IconChevronDown}
            boxSize={3.5}
            color="gray.400"
            ml="auto"
            transform={expanded && hasChildren ? 'rotate(180deg)' : 'rotate(0deg)'}
            transition="transform 200ms cubic-bezier(0.4, 0, 0.2, 1)"
            style={{ strokeWidth: 2.5 }}
          />
        </Flex>
      </NavLink>
      <Collapse in={expanded && !isCollapsed && hasChildren}>
        <Flex flexDir="column" gap="1px" marginBottom={1} marginLeft={4} paddingTop="1px" paddingLeft={2.5}>
          {props.children}
        </Flex>
      </Collapse>
    </Box>
  )
}

function Section(
  props: React.PropsWithChildren<{
    title: string
    rightElement?: React.ReactNode
    defaultIsOpen?: boolean
    isCollapsible?: boolean
    showWhenCollapsed?: boolean
  }>
) {
  const [expanded, setExpanded] = createPersistedState<boolean>(`koala:sidebar-section-${props.title}`)(
    props.defaultIsOpen ?? true
  )
  const { isCollapsed } = useSideNav()

  const onToggle = useCallback(() => {
    setExpanded((prev) => !prev)
  }, [setExpanded])

  const sideNavExpanded = !isCollapsed
  if (!props.showWhenCollapsed && isCollapsed) {
    return null
  }

  return (
    <Box role="group">
      {sideNavExpanded ? (
        <Flex alignItems="center" justifyContent="space-between" mb={0.5}>
          <Flex
            display="inline-flex"
            alignItems="center"
            gap={1}
            pl={1.5}
            pr={1.5}
            ml={1}
            userSelect="none"
            rounded="md"
            cursor={props.isCollapsible ? 'pointer' : undefined}
            onClick={props.isCollapsible ? onToggle : undefined}
            _hover={props.isCollapsible ? { bg: 'background.hover' } : undefined}
          >
            <GroupHeader>{props.title}</GroupHeader>
            {props.isCollapsible && (
              <Icon
                as={IconChevronRight}
                boxSize={3.5}
                color="gray.400"
                transform={expanded ? 'rotate(90deg)' : 'rotate(0deg)'}
                transition="transform 200ms cubic-bezier(0.4, 0, 0.2, 1)"
                style={{ strokeWidth: 2.5 }}
              />
            )}
          </Flex>
          {props.rightElement}
        </Flex>
      ) : React.Children.toArray(props.children).length > 0 ? (
        <Divider my={2} />
      ) : null}
      <Collapse in={expanded || isCollapsed || !props.isCollapsible} animateOpacity={false}>
        <Flex flexDir="column" gap="1px">
          {props.children}
        </Flex>
      </Collapse>
    </Box>
  )
}

function GroupHeader(props: React.PropsWithChildren<TextProps>) {
  return <Text fontSize="xs" fontWeight="medium" color="gray.500" letterSpacing="tight" {...props} />
}

interface NavLinkProps extends LinkProps {
  isActive?: boolean
  isSelected?: boolean
  title?: string
  icon?: React.ReactNode
  iconRight?: React.ReactNode
  menu?: React.ReactNode
  pageKey?: string
}

const NavLink = forwardRef<HTMLDivElement, React.PropsWithChildren<NavLinkProps>>(function NavLink(
  { isActive, isSelected, icon, title, children, menu, role = 'group', pageKey, iconRight, ...props },
  ref
) {
  const { isCollapsed } = useSideNav()
  const tooltip = title || (typeof children === 'string' ? children : undefined)
  isActive = isActive ?? window.location.pathname === props.href

  const { trackRecentNavItem } = useTrackRecentNavItems()

  const onClick = useCallback(() => {
    if (pageKey && typeof trackRecentNavItem === 'function') {
      trackRecentNavItem(pageKey)
    }
  }, [pageKey, trackRecentNavItem])

  return (
    <Tooltip label={isCollapsed ? tooltip : undefined} placement="right">
      <Box
        ref={ref}
        bg={isSelected ? 'gray.100' : isActive ? 'background.hover' : 'transparent'}
        cursor="pointer"
        rounded="md"
        userSelect="none"
        _hover={{ bg: isCollapsed ? undefined : 'background.hover' }}
        role={role}
        display="flex"
        alignItems="center"
        gap={1.5}
        paddingLeft={isCollapsed ? 0 : 2}
        paddingRight={isCollapsed ? 0 : 1.5}
      >
        <Link
          display="block"
          fontSize="13.5px"
          color={isActive ? 'gray.800' : 'gray.700'}
          fontWeight={isActive ? 'semibold' : 'medium'}
          textDecoration="none"
          letterSpacing="-0.015em"
          lineHeight="22px"
          flex="1 1 auto"
          _hover={{ textDecoration: 'none' }}
          isTruncated
          onClick={onClick}
          {...props}
        >
          <Flex
            as="span"
            gap={2}
            flex="1 1 auto"
            width="100%"
            justifyContent={isCollapsed ? 'center' : 'flex-start'}
            alignItems="center"
            paddingY={isCollapsed ? 1.5 : 1}
          >
            {icon && (
              <Box
                as="span"
                className="link-icon"
                display="inline-flex"
                flex="none"
                color={isActive || isSelected ? 'purple.600' : 'gray.500'}
              >
                {icon}
              </Box>
            )}
            {isCollapsed ? null : (
              <Text display="flex" alignItems="center" as="span" flex="1 1 auto" width="100%" isTruncated>
                {children}
              </Text>
            )}
            {iconRight && (
              <Box as="span" className="link-icon" display="inline-flex" flex="none" color="gray.500">
                {iconRight}
              </Box>
            )}
          </Flex>
        </Link>

        {!isCollapsed && !!menu && <Box flex="none">{menu}</Box>}
      </Box>
    </Tooltip>
  )
})

interface TopBarProps {}

export function TopBar(props: PropsWithChildren<TopBarProps>) {
  const layoutMode = useLayoutMode()
  const { content } = useTopBarContext()
  const { offScreen, onOpen } = useSideNav()

  if (layoutMode !== 'new') {
    return null
  }

  if (!content) {
    return null
  }

  return (
    <Box flex="none" width="100%" bg="white" color="gray.800" minH="48px" lineHeight={1.42} overflow="auto">
      <Flex
        alignItems="center"
        height="100%"
        pl={[2, 3, 4]}
        pr={[2, 3]}
        gap={2}
        borderBottom="1px solid"
        borderColor="border.lightest"
      >
        {offScreen && (
          <Flex flex="none" alignItems="center">
            <IconButton
              aria-label="Open Navigation"
              size="xs"
              variant="ghost"
              color="gray.400"
              opacity={0.8}
              _hover={{ color: 'gray.500', opacity: 1 }}
              icon={<IconLayoutSidebarLeftExpand size={20} strokeWidth={1.75} />}
              onClick={onOpen}
            />
          </Flex>
        )}
        <Flex flex="1 1 auto" alignSelf="stretch">
          {content}
          {props.children}
        </Flex>
      </Flex>
    </Box>
  )
}

interface IconWithIndicatorProps {
  icon: JSX.Element | TablerIcon | string | React.FC
  size?: string | number
  showIndicator?: boolean
}

function IconWithIndicator({ icon, size }: IconWithIndicatorProps) {
  return (
    <Box position="relative">
      <Iconify icon={icon} size={size} />
      <Box
        position="absolute"
        top="2px"
        right="2px"
        transform="translate(40%, -40%) scale(0.50)"
        transformOrigin="center"
        color="purple.600"
        backgroundColor="white"
        border={`2px solid white`}
        borderRadius="full"
      >
        <IconCircle size="100%" fill="currentColor" />
      </Box>
    </Box>
  )
}
